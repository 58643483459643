import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <>
                <p>
                    <strong>Tras l'èxit del primer llibre de Guàrdia Urbana de Barcelona...</strong>
                </p>

                <p>
                    Et presentem una de les <strong>novetats més destacades d'aquest 2022:</strong>
                    el llibre de les{" "}
                    <strong>
                        500 preguntes imprescindibles per accedir a la Guàrdia Urbana de Barcelona
                        PART 2.
                    </strong>
                </p>

                <p>
                    Un recopilatori de preguntes oficials que han aparegut en els exàmens de
                    convocatòries d'anys anteriors, juntament amb{" "}
                    <strong>
                        preguntes noves d'acord amb els temes actualitzats a les bases de 2022.
                    </strong>
                </p>

                <p>
                    Aquest llibre és el complement perfecte al primer volum, ideal per aprofundir en
                    el teu procés d'estudi. Totes les preguntes inclouen la{" "}
                    <strong>resposta correcta</strong> i, en moltes d'elles, hi trobaràs informació
                    ampliada.
                </p>

                <p>
                    Al llibre trobaràs tots els temes de les bases del temari, inclosos els{" "}
                    <strong>dos temes nous d'aquest any:</strong>
                </p>
                <ul>
                    <li>
                        <strong>L'Ordenança de protecció, tinença i venda d'animals.</strong>
                    </li>
                    <li>
                        <strong>Llei Orgànica 10/1995, de 23 de novembre, del Codi Penal:</strong>{" "}
                        amb tots els apartats rellevants del Llibre II, com lesions, delictes contra
                        la llibertat, tortures, integritat moral, llibertat i indemnitat sexuals,
                        recursos naturals, salut pública, seguretat vial, infidelitat en la custòdia
                        de documents, delictes d'omissió, delictes comesos pels funcionaris públics,
                        violència de gènere i delictes d'odi.
                    </li>
                </ul>

                <p>
                    <strong>Amb la compra del llibre</strong>, t'habilitem de manera{" "}
                    <strong>TOTALMENT GRATUÏTA</strong> el mòdul de les 500 vol. 2 durant un any.
                </p>

                <p>
                    Aquest mòdul es pot fer servir a través de l'aplicació <strong>iGuB</strong>, en
                    qualsevol moment i des de qualsevol lloc.
                </p>

                <p>
                    <strong>Posiciona't entre els millors!</strong>
                </p>
            </>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Amb aquest llibre i el mòdul exclusiu, tens tot el que necessites per destacar i assegurar el teu futur a la Guàrdia Urbana. No deixis escapar aquesta oportunitat!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;

/*import React from "react";
import ProductTemplate from "./product";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import { Link } from "gatsby";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>            
            <div className="container lg:px-0 py-20">
                <Title>El Libro</Title>
                <div className="mt-10">
                    <Text className="text-justify">
                        Tras l'èxit del primer llibre de Guàrdia Urbana de Barcelona
                        <br />
                        <br />
                        Et presentem una de les novetats més destacades d'aquest 2022
                        <br />
                        <br />
                        El llibre de les 500 preguntes imprescindibles per accedir a la Guàrdia
                        Urbana de Barcelona PART 2.
                        <br />
                        <br />
                        Un recopilatori preguntes oficials que han aparegut en els exàmens de les
                        convocatòries d'anys anteriors i preguntes noves d'acord amb els nous temes
                        exigits a les bases de 2022.
                        <br />
                        <br />
                        Un complement perfecte amb el primer llibre per profunditzar en el teu
                        procés d'estudi.
                        <br />
                        <br />
                        Totes les preguntes contenen la resposta correcta i en moltes d'elles hem
                        afegit ampliació d'informació.
                        <br />
                        <br />
                        Trobaràs tots els temes de les bases del temari, inclosos els dos temes nous
                        d'aquest any:
                        <br />
                        <br />
                        -L'Ordenança de protecció, tinença i venda d'animal.
                        <br />
                        <br />
                        -Llei Orgànica 10/1995, de 23 de novembre, del Codi Penal. Llibre II. Títol
                        III de les lesions. Títol VI delictes contra la llibertat. Títol VII de les
                        tortures i altres delictes contra la integritat moral. Títol VIII Delictes
                        contra la llibertat i indemnitat sexuals. Títol XVI Capítol III delictes
                        contra els recursos naturals i el medi ambient. Capítol IV Delictes relatius
                        a la protecció de la flora, fauna i animals domèstics. Títol XVII Capítol
                        III delictes contra la salut pública. Capítol IV delictes contra la
                        seguretat vial. Títol XIX Capítol IV de la infidelitat en la custòdia de
                        documents i de la violació de secrets. Títol XX Capítol II de l'omissió del
                        deure d'impedir delicte o promoure la seva persecució. Títol XXI Capítol V
                        Delictes comesos pels funcionaris públics contra les garanties
                        constitucionals. La violència de Gènere i els delictes d'odi a l'ordenament
                        jurídic.
                        <br />
                        <br />
                        Amb la compra del llibre t'habilitem de manera TOTALMENT GRATUÏTA el mòdul
                        de les 500 vol. 2. durant un any.
                        <br />
                        <br />
                        Un mòdul que podràs fer servir a través de l'aplicació iGuB a qualsevol
                        moment i allà on vulguis.
                        <br />
                        <br />
                        Posiciona't entre els millors!
                        <br />
                        <br />ç Els enviaments es realitaran a partir del dilluns 25 d'abril.
                    </Text>
                </div>
            </div>
            
            <div className="bg-gray-100 bg-opacity-50">
                <div className="container lg:px-0 py-20 ">
                    <Title>¿Cómo puedo adquirirlo?</Title>
                    <Text className="text-justify">
                        Para adquirir el libro lo puedes hacer desde nuestra sección{" "}
                        <Link to="/tienda" className="default-link">
                            Tienda
                        </Link>{" "}
                        haciendo clic en el <strong>botón de añadir a la cesta</strong>. Recuerda
                        que puedes combinar diferentes productos en una misma compra.
                        <br />
                        <br />
                        Una vez recibamos la orden de compra procederemos al envío del libro. El
                        envío se realiza a través de la empresa GLS en la modalidad de envío
                        certificado. La empresa GLS os enviará un mail para que podáis saber en todo
                        momento donde se encuentra vuestro libro y si es necesario os llamarán por
                        teléfono para contactar con vosotros. El tiempo en ser entregado es
                        aproximadamente 48 horas.
                        <div className="text-left text-xs font-montserrat italic mt-10">
                            * Nos comprometemos en realizar el envío el día siguiente de realizar el
                            pago aunque no podemos hacernos responsables de los posibles retrasos
                            que la empresa transportista pueda ocasionar.
                        </div>
                    </Text>
                </div>
            </div>
        </ProductTemplate>
    );
};
export default Product;*/
